<template>
  <div class="container">
    <div class="newsList"
		v-infinite-scroll="loadData" 
    infinite-scroll-disabled="disabled">			
				<div class="item" 
				v-for="item in newsList" 
				:key="item.article_id"
				@click="openBlank('WapDetails',item.article_id)">
					<img :src="item.thumb?item.thumb:defaultSrc" class="info-imgage" />
					<div class="info-text">
						<div class="text-title">{{item.title}}</div>
						<div class="text-time">{{item.update_time | formatDate}}</div>
					</div>	
				</div>
				<div v-if="disabled && !!newsList.length" class="no_more">-没有更多了-</div>
				<el-empty description="暂无数据" v-if="!newsList.length"></el-empty>
			</div>
			
  </div>
</template>

<script>
import { formatDate } from "@/utils/timeStamp.js";
export default {
	// 格式化时间戳
  filters: {
    formatDate(time) {
      time = time * 1000;
      let date = new Date(time);
      return formatDate(date, "yyyy.MM.dd");
    },
  },
  data() {
    return {
			defaultSrc:require('@/assets/images/default.png'),  
			page:1,
			// totalPage:1,
			newsList:[], 
      loading:false,
      noMore:false, 
    };
  },
  components: {},
	mounted(){
		// this.getNewsList();
	},
	computed: {
    disabled () {
      return this.loading || this.noMore
    }
  },
	methods: {
		//上拉加载
    loadData(){
			this.loading = true
      setTimeout(() => {
        this.getNewsList()
        this.loading = false        
      }, 500)
    },		
		//资讯列表
    getNewsList() {						
      this.$http.get('/api/wap/news/getNewsList?page='+this.page)
      .then((res) => {
				const {list,totalPage }=res.data;
        this.newsList = this.newsList.concat(list);				
				this.page++;
				if (this.page > totalPage) {
					this.noMore=true;					
				} 				
      })			
    },	

		openBlank(name,id){				
      let routeUrl = this.$router.resolve({
        name,
        query:{id},	
      });
      window.open(routeUrl.href, '_self');
    },
	}
};
</script>

<style lang='less' scoped>
.container{
	width: 100%;
	min-height: calc(100vh - 7.24rem);
	background-color: #f5f5f5;
	/deep/.newsList{
		width: 100%;
		padding: .30rem .24rem;
		.u-empty{
			width: 100%;
			height: 1.60rem;
			margin-top: 1.50rem !important;
		}
		.item{
			display: flex;
			justify-content: space-between;
			width: 100%;
			padding: .25rem;
			background-color: #fff;
			margin-bottom: .30rem;
			border-radius: .15rem;
			&.item:last-child{
				margin-bottom: 0;
			}
			.info-imgage{
				width: 2.26rem;
				height: 1.60rem;
				border-radius: .10rem;
				margin-right: .34rem;
				object-fit: cover;
    		object-position: center;
				overflow: hidden;
			}
			.info-text{
				width: calc(100% - 2.60rem);
				.text-title{
					font-size: .30rem;
					text-align: left;
					color: #333333;
					line-height: .48rem;
					margin-bottom: .24rem;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp:2;
					-webkit-box-orient: vertical;					
				}
				.text-time{
					font-size: .24rem;
					text-align: left;
					color: #cccccc;
				}
			}
		}
	}
}
</style>

